import styled, { keyframes } from "styled-components";
import { useApplicationContext } from "../../context/application-context";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .loader {
    width: 200px;
    height: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    overflow: hidden;
    color: ${(props) => props.color};
    text-shadow: 0 0 0 ${(props) => props.color},
      10ch 0 0 ${(props) => props.color};
    background: linear-gradient(${(props) => props.color} 0 0) bottom left/0%
      14px no-repeat;
    animation: l14 4s infinite;
    border: 2px solid ${(props) => props.color};
    border-radius: 10px;
  }
  .loader:before {
    content: "";
  }
  @keyframes l14 {
    80% {
      text-shadow: 0 0 0 ${(props) => props.color},
        10ch 0 0 ${(props) => props.color};
      background-size: 100% 14px;
    }
    100% {
      text-shadow: -10ch 0 0 ${(props) => props.color},
        0 0 0 ${(props) => props.color};
    }
  }
`;

const InitialLoader = () => {
  const { tenantSettings } = useApplicationContext();

  return (
    <LoaderContainer
      color={
        tenantSettings?.tenantConfig?.colorPalette?.primaryColor || "black"
      }
    >
      <img
        src={tenantSettings.tenantConfig.brandLogo.whiteBgLogo}
        alt={"logo"}
        width={175}
        height={"auto"}
        style={{ marginBottom: "15px" }}
      />
      <div className="loader"></div>
    </LoaderContainer>
  );
};

export default InitialLoader;
