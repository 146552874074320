export const openPopup = (url: string, onDataReceived: (data: any) => void) => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const popupWidth = 600;
  const popupHeight = 400;
  const left = (screenWidth - popupWidth) / 2;
  const top = (screenHeight - popupHeight) / 2;

  const features = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},toolbar=no,location=no,menubar=no,scrollbars=yes,resizable=yes`;

  const popupWindow = window.open(url, "_blank", features);

  // Listen for messages from the popup window
  window.addEventListener("message", (event) => {
    // Ensure that the message is from the popup window
    if (event.source === popupWindow) {
      // Call the provided callback with the received data
      onDataReceived(event.data?.token);
    }
  });
};
