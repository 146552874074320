import Cookies from "js-cookie";

export interface IDomStorage {
  isExist: boolean;
  data: any;
}

export const LocalStorageExistence = (
  keyName: string,
  isStorageStringType: boolean = false
) => {
  let domStorage: IDomStorage = {
    isExist: false,
    data: undefined,
  };
  let storageData = localStorage.getItem(keyName);

  if (
    storageData === null ||
    storageData === "null" ||
    storageData === "undefined" ||
    storageData === undefined
  ) {
    return domStorage;
  } else {
    domStorage = {
      isExist: true,
      data: !isStorageStringType ? JSON.parse(storageData) : storageData,
    };
    return domStorage;
  }
};

export const LocalStorageClear = (keyName: string, all?: boolean) => {
  if (all) {
    localStorage.clear();
    return;
  }
  localStorage.removeItem(keyName);
  return;
};

export const SessionStorageExistence = (
  keyName: string,
  isStorageStringType: boolean = false
) => {
  let domStorage: IDomStorage = {
    isExist: false,
    data: undefined,
  };
  let storageData = sessionStorage.getItem(keyName);

  if (
    storageData === null ||
    storageData === "null" ||
    storageData === "undefined" ||
    storageData === undefined
  ) {
    return domStorage;
  } else {
    domStorage = {
      isExist: true,
      data: !isStorageStringType ? JSON.parse(storageData) : storageData,
    };
    return domStorage;
  }
};

export const SessionStorageClear = (keyName: string, all?: boolean) => {
  if (all) {
    sessionStorage.clear();
    return;
  }
  sessionStorage.removeItem(keyName);
  return;
};

export const CookiesExistence = (
  keyName: string,
  isStorageStringType: boolean = false
) => {
  let domStorage: IDomStorage = {
    isExist: false,
    data: undefined,
  };
  let storageData = Cookies.get(keyName);

  if (
    storageData === null ||
    storageData === "null" ||
    storageData === "undefined" ||
    storageData === undefined
  ) {
    return domStorage;
  } else {
    domStorage = {
      isExist: true,
      data: !isStorageStringType ? JSON.parse(storageData) : storageData,
    };
    return domStorage;
  }
};

export const CookiesClear = (keyName: string, all?: boolean) => {
  Cookies.remove(keyName);
  return;
};

export const DomainCookiesClear = (keyName: string) => {
  // const domain = window.location.hostname.includes("localhost")
  //     ? "localhost"
  //     : ".skilliq.co";

  // Cookies.remove(keyName, {
  //     domain: domain
  // });

  Cookies.remove(keyName);
  return;
};
