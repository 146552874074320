import { HMTableResponseInterface } from "../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/hm-dtos";

export interface IManualScoring {
  _isExist?: boolean;
  _id?: string;
  id?: string | number;
  instruction: IMSInstruction;
  category: IMSCategory[];
  overallScore: number;
  config: ManualFeedbackConfig;
}

export interface LoadingConfig {
  isLoading: boolean;
  tip: string;
}

export const defaultLoadingConfig: LoadingConfig = {
  isLoading: false,
  tip: "",
};

export interface ManualFeedbackConfig {
  isFeedBackPresent: boolean;
}

export interface UpdateManualScoringPublic {
  userId: number;
  hmId: number;
  stageId: number;
  courseId: number;
  workFlowId: number;
  courseMetaData?: CourseMetaDataDto;
  score?: IManualScoring;
  interviewId: number;
  attemptNum: number;
  candidateName: string;
  meetingLink?: string;
  redirectUrl?: string;
  tenantId?: number;
}

export interface CourseMetaDataDto {
  stageName: string;
  courseName: string;
}

export interface IManualScoringProps {
  record: UpdateManualScoringPublic;
  setIsFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedbackSubmitted: boolean;
  onCloseOverviewDrawer?: () => void;
}

export interface IMSInstruction {
  title: string;
  constraints?: IMSConstraints;
  ratingCriteria: IRatingCriteria[];
}

export interface IMSCategory {
  id: string;
  title?: string;
  description?: string;
  questions: IMSCategoryQuestions[];
  type?: ManualCategoryType;
}

export enum ManualCategoryType {
  NORMAL = "NORMAL",
  FINAL_FEEDBACK = "FINAL_FEEDBACK",
}

export interface IMSCategoryQuestions {
  id: string;
  pQuestion: string;
  lQuestion: string;
  ratingCriteria: IRatingCriteria[];
  gainedMarks?: number;
  isEditable: boolean;
  comment?: string;
}

export interface IMSConstraints {
  categoryNumber: number;
  minQuestionNumber: number;
  minScore: number;
  maxScore: number;
}

export interface IRatingCriteria {
  score: number | string;
  criteria: string;
}

export interface IManualScoringProps {
  record: UpdateManualScoringPublic;
}
