import React, { FC } from "react";
import { FourDotLoaderContainer } from "./style";
// import { IDefaultTheme } from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";
import {
  EMessagePosition,
  ELoaderThemes,
  DefaultLoaderProps,
} from "../progress-bar-loader";
import { IDefaultTheme } from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";

export interface IFourDotLoader extends DefaultLoaderProps {
  primaryColor?: string;
  secondaryColor?: string;
}

export const generateMessage = (
  message?: React.ReactNode,
  messageTextStyles?: React.CSSProperties
) => {
  return (
    <div className="message-text" style={messageTextStyles}>
      {message}
    </div>
  );
};

export const generatePosition = (messagePosition?: EMessagePosition) => {
  let position = "";
  switch (messagePosition) {
    case EMessagePosition.TOP:
      position = "column-reverse";
      break;
    case EMessagePosition.BOTTOM:
      position = "column";
      break;
    case EMessagePosition.RIGHT:
      position = "row";
      break;
    case EMessagePosition.LEFT:
      position = "row-reverse";
      break;
  }
  return position;
};

export const generateTheme = (
  loaderTheme: ELoaderThemes,
  theme: IDefaultTheme
) => {
  let newTheme = theme.colorPalette.secondaryColor;
  switch (loaderTheme) {
    case ELoaderThemes.PRIMARY:
      newTheme = theme.colorPalette.primaryColor;
      break;
    case ELoaderThemes.SECONDARY:
      newTheme = theme.colorPalette.secondaryColor;
      break;
    case ELoaderThemes.TERTIARY:
      newTheme = theme.colorPalette.tertiaryColor!;
      break;
  }
  return newTheme;
};

export const FourDotLoader: FC<IFourDotLoader> = (_props) => {
  const {
    message,
    messagePosition,
    messageTextStyles,
    primaryColor,
    secondaryColor,
    loaderTheme,
    background,
  } = _props;
  return (
    <FourDotLoaderContainer
      loaderTheme={loaderTheme}
      flexDirection={generatePosition(messagePosition)}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      background={background}
    >
      <div className="loader"></div>
      {message && generateMessage(message, messageTextStyles)}
    </FourDotLoaderContainer>
  );
};
