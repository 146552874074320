import styled from "styled-components";

export const IntgSetupContainer = styled.div`
  width: 100%;
  height: calc(100% - 68px);
  padding-top: 68px;
  justify-content: space-evenly;
  /* flex-direction: column; */
  align-items: center;
  display: flex;

  .ntg-main-internal-wrapper {
    justify-content: space-evenly;
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
  }
  .card-wrapper {
    /* justify-content: space-evenly;
    align-items: center;
    display: flex; */
  }
`;

interface IntgRowContainerProps {
  isIntg: boolean;
}

export const IntgRowContainer = styled.div<IntgRowContainerProps>`
  .ant-card-actions {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    background: ${(props) =>
      !props.isIntg ? (props) => props.theme.colorPalette.primaryColor : ""};
    border-top: 1px solid #f0f0f0;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1890ff;
    background: green;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

  .ant-card-cover {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
