// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Button, Modal, List, Avatar, message } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useApplicationContext } from "../../../../context/application-context";
import axios from "axios";
import { CalendarListItem } from "../../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/intg/ms-intg";
import { FourDotLoader } from "../../../../components/loaders/four-dot-loader";
import { INTG_ENDPOINTS } from "../../../../utils/endpoints";

// Define the component
interface Props {
  visible: boolean;
  onClose: () => void;
  onOk: (calenderItem: CalendarListItem) => void;
  isLoadingProp: boolean;
}

const CalenderListModal: React.FC<Props> = ({
  visible,
  onClose,
  onOk,
  isLoadingProp,
}) => {
  const [selectedItem, setSelectedItem] = useState<
    CalendarListItem | undefined
  >(undefined);

  const [calendarList, setCalendarList] = useState<CalendarListItem[]>();
  const [isLoading, setIsLoading] = useState(false);

  const { tenantSettings } = useApplicationContext();
  const colorPalette = tenantSettings.tenantConfig.colorPalette;

  useEffect(() => {
    getCalendarList();
  }, []);

  const handleItemClick = (value: CalendarListItem) => {
    if (isLoading) {
      message.warning("Please wait, we are processing your request!");
      return;
    }
    setSelectedItem(value);
  };

  const getCalendarList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("teams-token");

      const response = await axios({
        method: "GET",
        url: `${INTG_ENDPOINTS.GET_ALL_CAL}?token=${token}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data && response.data?.status === 200) {
        setCalendarList(response.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      message.error("Something went wrong!!!");
    }
  };

  const onClickOk = (selectedValue: CalendarListItem) => {
    if (selectedValue) {
      onOk(selectedValue);
    } else {
      message.info("Please select a calendar");
    }
  };

  return (
    <Modal
      title="Please select one of your calenders"
      visible={visible}
      destroyOnClose={true}
      // onCancel={onClose}
      // onOk={() => {
      //   // Handle OK button click, if needed
      //   onClickOk(selectedItem!);
      // }}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          style={{
            borderColor: colorPalette.primaryColor,
            color: colorPalette.primaryColor,
          }}
        >
          Cancel
        </Button>,
        <Button
          loading={isLoadingProp}
          key="ok"
          type="primary"
          onClick={() => onClickOk(selectedItem!)}
          style={{
            background: colorPalette.primaryColor,
            color: "white",
            border: "none",
          }}
        >
          OK
        </Button>,
      ]}
    >
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FourDotLoader />
        </div>
      ) : (
        <List
          dataSource={calendarList!}
          renderItem={(item: CalendarListItem) => (
            <List.Item
              key={item.id}
              onClick={() => handleItemClick(item)}
              style={{
                background: selectedItem?.name === item.name ? "#1890ff" : "",
                color: selectedItem?.name === item.name ? "white" : "black",
                cursor: "pointer",
                paddingLeft: "20px",
                listStyleType: "disc",
              }}
            >
              <List.Item.Meta
                avatar={<CaretRightOutlined />}
                title={
                  <div
                    style={{ color: selectedItem === item ? "white" : "black" }}
                  >
                    {item.name}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
    </Modal>
  );
};

export default CalenderListModal;
