import React, { useEffect, useState } from "react";
import { HmMeetingContainer } from "./style";
import { TeamsMeeting } from "../../../components/meeting-gui-hm";
import { ManualScoringConstraintsPublic } from "../../../components/manual-scoring-cons-public";
import { metaDataParamsKey } from "../../middleware";
import HeaderTitle from "../../../components/header-with-title";
import { Button, Drawer, Result } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { isMobileWidth } from "../../../app-routes";
import { DecodeUpdateManualScoringPublic } from "./interface";
import { UpdateManualScoringPublic } from "../../../components/manual-scoring-cons-public/interface";
import HmDrawerMobile from "./mobile-drawer-hm";

export const mapperMetaData = (paramsData: DecodeUpdateManualScoringPublic) => {
  const actualUpdateData: UpdateManualScoringPublic = {
    userId: paramsData.uid,
    hmId: paramsData.hid,
    stageId: paramsData.sid,
    courseId: paramsData.cid,
    workFlowId: paramsData.wfid,
    courseMetaData: {
      stageName: paramsData.cmd?.sn!,
      courseName: paramsData.cmd?.cn!,
    },
    interviewId: paramsData.iid,
    attemptNum: paramsData.an,
    candidateName: paramsData.cn,
    meetingLink: paramsData.ml,
    redirectUrl: paramsData.ru,
    tenantId: paramsData.tid,
  };

  return actualUpdateData;
};

const HmMeeting = () => {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [updateData, setUpdateData] = useState<any>();

  useEffect(() => {
    const updateDataLocal: DecodeUpdateManualScoringPublic = JSON.parse(
      sessionStorage.getItem(metaDataParamsKey)!
    );

    setUpdateData(mapperMetaData(updateDataLocal));

    const localData = localStorage.getItem(
      `${updateDataLocal.uid}${updateDataLocal.hid}${updateDataLocal.sid}`
    );

    if (localData === "true") {
      setIsFeedbackSubmitted(true);
    }
  }, []);

  return (
    <HmMeetingContainer>
      {!isMobileWidth && (
        <div className="form-div">
          {isFeedbackSubmitted && updateData ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Result
                title="Your feedback back is already captured!"
                extra={
                  updateData.redirectUrl && (
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        window.open(updateData?.redirectUrl, "_self");
                      }}
                    >
                      Login to check your response
                    </Button>
                  )
                }
              />
            </div>
          ) : (
            updateData && (
              <ManualScoringConstraintsPublic
                record={updateData}
                isFeedbackSubmitted={isFeedbackSubmitted}
                setIsFeedbackSubmitted={setIsFeedbackSubmitted}
              />
            )
          )}
        </div>
      )}

      {isMobileWidth && <HmDrawerMobile />}

      {updateData && (
        <div className="meeting-div">
          {updateData?.meetingLink && (
            <TeamsMeeting
              userId={{
                microsoftTeamsUserId: localStorage.getItem("ms-id")!,
                isAnonymous: false,
              }}
              meetingUrl={updateData?.meetingLink}
              // sleep company meeting link
              // meetingUrl={
              //   "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODg5OGNmYTctMDkwMC00OTM3LThjZmEtY2U1MzAzMzYwOGJh%40thread.v2/0?context=%7b%22Tid%22%3a%2288ca5517-8189-4c12-a938-4d17803d4465%22%2c%22Oid%22%3a%2239fbe0f0-17e5-4142-8ad5-b1dcde244487%22%7d"
              // }
              token={localStorage.getItem("teams-token")!}
              locator={updateData?.meetingLink}
            />
          )}
        </div>
      )}
    </HmMeetingContainer>
  );
};

export default HmMeeting;
