export enum UserRole {
  ADMIN = "ADMIN",
  FACULTY = "FACULTY",
  STUDENT = "STUDENT",
  OPS_MANAGER = "OPS_MANAGER",
  TEAM_LEADER = "TEAM_LEADER",
  COORDINATOR = "COORDINATOR",
  CONSULTANT = "CONSULTANT",
  H_MANAGER = "H_MANAGER",
  LEAD = "LEAD"
}
