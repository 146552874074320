import React, { FC } from "react";
import { ProgressBarLoaderContainer } from "./styled";
import { IDefaultTheme } from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";

export interface DefaultLoaderProps {
  loaderTheme?: ELoaderThemes;
  message?: React.ReactNode;
  messagePosition?: EMessagePosition;
  messageTextStyles?: React.CSSProperties;
  background?: string;
}

export enum ELoaderThemes {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export enum EMessagePosition {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export const generateTheme = (
  loaderTheme: ELoaderThemes,
  theme: IDefaultTheme
) => {
  let newTheme = theme.colorPalette.secondaryColor;
  switch (loaderTheme) {
    case ELoaderThemes.PRIMARY:
      newTheme = theme.colorPalette.primaryColor;
      break;
    case ELoaderThemes.SECONDARY:
      newTheme = theme.colorPalette.secondaryColor;
      break;
    case ELoaderThemes.TERTIARY:
      newTheme = theme.colorPalette.tertiaryColor!;
      break;
  }
  return newTheme;
};

export interface ILoaderStyles {
  loaderTheme?: ELoaderThemes;
  flexDirection?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
  background?: string;
}

export interface IProgressBarLoader extends DefaultLoaderProps {
  primaryColor?: string;
  secondaryColor?: string;
}

export const ProgressBarLoader: FC<IProgressBarLoader> = (_props) => {
  return (
    <ProgressBarLoaderContainer className="custom-loader"></ProgressBarLoaderContainer>
  );
};
