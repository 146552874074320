import styled from "styled-components";

export const HmMeetingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);

  display: flex;

  .form-div {
    width: 40%;
    height: 100%;
    overflow: auto;
    padding: 15px;
    padding-left: 34px;
    scroll-behavior: smooth;
    border-right: 2px solid;
  }
  .meeting-div {
    width: 60%;
    height: calc(100vh - 60px);
    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;
