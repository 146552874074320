import React, { useEffect } from "react";
import axios from "axios";
import { getTokenByCodePayloadDto } from "../../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/intg/ms-intg";
import { useApplicationContext } from "../../../../context/application-context";
import { message } from "antd";
import { INTG_ENDPOINTS } from "../../../../utils/endpoints";
import { ProgressBarLoader } from "../../../../components/loaders/progress-bar-loader";

const GetCodeMs = () => {
  const { tenantSettings } = useApplicationContext();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")!);
    if (user?.id) {
      // Function to extract code from URL
      const extractCodeFromURL = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (code) {
          // Call API using Axios with the extracted code
          callApiWithCode(code);
        } else {
          console.error("Code parameter not found in the URL");
        }
      };

      const getTokenByCode = async (code: string) => {
        try {
          const payload: getTokenByCodePayloadDto = {
            email: user.email!,
            name: `${user.firstName} ${user.lastName}`,
            userId: user?.id?.toString()!,
            tenantId: tenantSettings.id.toString(),
            isIntegrated: true,
            code: code,
            tenantUrl: window.location.origin,
            msTeamsId: localStorage.getItem("ms-client-id")!,
          };

          const response = await axios({
            method: "POST",
            url: `${INTG_ENDPOINTS.GET_TOKEN_BY_CODE}`,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: payload,
          });

          if (response.data.status === 200) {
            return response.data?.result?.accessToken;
          } else return null;
        } catch (er) {
          message.error("Something wrong in getting token....");
        }
      };

      // Function to make API call with the code
      const callApiWithCode = async (code: string) => {
        try {
          let token = await getTokenByCode(code);

          const dataToSend = { token };

          window.opener.postMessage(dataToSend, "*");
          setTimeout(() => {
            window.close();
          }, 500);
        } catch (error) {
          console.error("Error making API call:", error);
        }
      };

      // Call the function to extract code from URL on component mount
      extractCodeFromURL();
    } else {
      console.log("Inside else user app - get code");
    }
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Setting up your calender....Please hold on
      <ProgressBarLoader />
    </div>
  );
};

export default GetCodeMs;
