import { CustomResponse } from "../../../../../api-client/services/tenant";

export interface GetTenantSettingsResponse extends CustomResponse {
  data?: TenantSettingsResponse;
}

export interface TenantSettingsResponse {
  id: number;
  tenantName: string;
  tenantConfig: ITenantConfig;
}

export interface TenantSettingsResponse {
  id: number;
  tenantName: string;
  tenantConfig: ITenantConfig;
}

export interface ITenantConfig {
  loginBgURL: string;
  brandLogo: IBrandLogo;
  quotes: IQuotes;
  quotesColor: IQuotesColor;
  colorPalette: IColorPalette;
  fontFamilyConfig?: IFontFamilyConfig;
  helmet?: IHelmet;
  isMaintenance?: string;
  category: ETenantCategory;
  updatedAt?: string;
  isAudioReadOutRequired?: boolean;
  admin?: ITenantConfig;
}

export enum ETenantCategory {
  COLLEGE = "COLLEGE",
  CORPORATE = "CORPORATE",
}

export interface IBrandLogo {
  darkBgLogo: string;
  whiteBgLogo: string;
}

export interface IQuotes {
  brandLogoTags: string;
  headline: string;
  subHeadline: string;
  brandingTag?: string; //Made with❣️by Refactor Academy
}

export interface IQuotesColor {
  brandLogoTags: string;
  headline: string;
  subHeadline?: string;
  demoText?: string;
  brandingTag?: string;
}

export interface IColorPalette {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor?: string;
  quaternaryColor?: string;
  quinaryColor?: string;
  whiteColor?: string;
  primaryGradientColor?: string;
  lightPink?: string;
  lightPurple?: string;
  primaryGray?: string;
  darkGray?: string;
  dangerColor?: string;
  blackColor?: string;
  darkGreen?: string;
  lightGreen?: string;
  redColor?: string;
  backgroundBlack?: string;
  backgroundSecondaryBlack?: string;
  darkBorderColor?: string;
  backgroundTertiaryBlack?: string;
  successColor?: string;
  secondaryGrayColor?: string;
  greenColor?: string;
  lightDangerColor?: string;
  lightGrayColor?: string;
}
export interface IFontFamilyConfig {
  primaryFontFamily: string;
  secondaryFontFamily: string;
}

export interface IHelmet {
  title?: string;
  description?: string;
  favicon?: string;
  thumbnailUrl?: string;
}
export interface IDefaultTheme {
  colorPalette: IColorPalette;
  fonts: IFontFamilyConfig;
}

export interface DefaultTheme {
  theme: IDefaultTheme;
}

export const othersColorPalette = {
  whiteColor: "#ffffff",
  primaryGradientColor:
    "linear-gradient(180deg, rgba(102, 45, 145, 0.3) 0%, rgba(217, 181, 243, 0.15) 96.35%)",
  lightPink: "rgba(255, 145, 77, 0.25)",
  lightPurple: "#460D71",
  primaryGray: "#dadada",
  darkGray: "#606060",
  dangerColor: "#BE1414",
  blackColor: "#000000",
  darkGreen: "#189A6D",
  lightGreen: "#06B657",
  redColor: "#F11E1E",
  backgroundBlack: "#1F1F1F",
  backgroundSecondaryBlack: "#505050",
  darkBorderColor: "#828282",
  backgroundTertiaryBlack: "#777777",
  successColor: "#008000",
  secondaryGrayColor: "#D9D9D9",
  greenColor: "#0A8B5F",
  lightDangerColor: "#F11E1E",
  lightGrayColor: "#EFEFEF",
};

export const defaultColorPalette: IColorPalette = {
  primaryColor: "#662d91",
  secondaryColor: "#729FFD",
  tertiaryColor: "#E9EBF6",
  ...othersColorPalette,
};

export const defaultFontFamilyConfig: IFontFamilyConfig = {
  primaryFontFamily: "QuickSand",
  secondaryFontFamily: "Roboto",
};

export const defaultHelmet: IHelmet = {
  title: "Skill IQ",
  description:
    "Supercharge Your Career and Secure Your Dream Job with Skill IQ",
  favicon:
    "https://refactor-platform-store.objectstore.e2enetworks.net/0664a4e3-f010-466a-b13b-d3f28e0b6c28_FinalLogo_Favicon.png",
  thumbnailUrl:
    "https://refactor-platform-store.objectstore.e2enetworks.net/37a7be10-ec80-4ac7-99b2-7ebc01cea049_Skill_IQ_logo_purple_500px_X_500px_for white_background.png",
};

export const defaultBrandLogo: IBrandLogo = {
  whiteBgLogo:
    "https://refactor-platform-store.objectstore.e2enetworks.net/37a7be10-ec80-4ac7-99b2-7ebc01cea049_Skill_IQ_logo_purple_500px_X_500px_for white_background.png",
  darkBgLogo:
    "https://refactor-platform-store.objectstore.e2enetworks.net/2d16281d-1bf0-4038-be36-709dbdc8800a_FinalLogo_BlueBG_trimmed.png",
};

export const defaultQuotes: IQuotes = {
  brandLogoTags: "An AI Adaptive Interview Platform",
  headline: "Step into Your Dream Job",
  subHeadline: "Let our skill evaluation tools guide you.",
};

export const defaultQuotesColor: IQuotesColor = {
  brandLogoTags: othersColorPalette.whiteColor,
  headline: othersColorPalette.whiteColor,
  brandingTag: othersColorPalette.whiteColor,
};

export const defaultTenantConfig: ITenantConfig = {
  loginBgURL:
    "https://refactor-platform-store.objectstore.e2enetworks.net/af5dc733-f5db-4dc4-9667-066c08f67170_loginBg.png",
  quotes: defaultQuotes,
  quotesColor: defaultQuotesColor,
  brandLogo: defaultBrandLogo,
  colorPalette: defaultColorPalette,
  fontFamilyConfig: defaultFontFamilyConfig,
  helmet: defaultHelmet,
  isMaintenance: "false",
  category: ETenantCategory.CORPORATE,
  updatedAt: "21-12-2023-06:00pm",
};

export const defaultTenantSettings: TenantSettingsResponse = {
  id: 1,
  tenantName: "app",
  tenantConfig: defaultTenantConfig,
};

export const createTheme = (
  tenantSettings: TenantSettingsResponse
): IDefaultTheme => {
  return {
    colorPalette: {
      ...defaultColorPalette,
      ...tenantSettings.tenantConfig.colorPalette,
    },
    fonts: {
      ...tenantSettings.tenantConfig.fontFamilyConfig!,
    },
  };
};
