import styled from "styled-components";

export const MobileDrawerContainer = styled.div`
  .ant-drawer-body {
    flex: 1;
    min-width: 0;
    min-height: 0;
    padding: 5px !important;
    overflow: auto;
  }
`;
