import { FC } from "react";
import { Helmet } from "react-helmet";
import { ITenantHelmetProps } from "../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/TenantHelmet";

const TenantHelmet: FC<ITenantHelmetProps> = ({ helmetProps }) => {
  const { title, description, favicon, thumbnailUrl } = helmetProps;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {favicon && <link rel="icon" href={favicon} />}
      {thumbnailUrl && <link rel="apple-touch-icon" href={thumbnailUrl} />}
    </Helmet>
  );
};

export default TenantHelmet;
