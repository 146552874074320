var CryptoJS = require("crypto-js");

export const encryptText = (inputText: string, key: string) => {
  return CryptoJS.AES.encrypt(inputText, key).toString();
};

export const decryptText = (encryptedText: string, key: string) => {
  let bytes = CryptoJS.AES.decrypt(encryptedText, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};
