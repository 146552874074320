import axios from "axios";
import { useEffect, useState } from "react";
import { MEETING_ENDPOINTS } from "../../../utils/endpoints";
import { TeamsMeetingCandidate } from "../../../components/meeting-gui-candidate";
import { metaDataParamsKey } from "../../middleware";
import { UpdateManualScoringPublic } from "../../../components/manual-scoring-cons-public/interface";

const CandidateMeeting = () => {
  const [acsToken, setAcsToken] = useState<string>();
  const [updateData, setUpdateData] = useState<any>();

  useEffect(() => {
    getCandidateAccessToken();
  }, []);

  useEffect(() => {
    const updateDataLocal: UpdateManualScoringPublic = JSON.parse(
      sessionStorage.getItem(metaDataParamsKey)!
    );

    setUpdateData(updateDataLocal);
  }, []);

  const getCandidateAccessToken = async () => {
    const response = await axios({
      method: "GET",
      url: `${MEETING_ENDPOINTS.GET_ACS_TOKEN}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setAcsToken(response?.data);
  };

  return (
    <div>
      {acsToken && updateData && (
        <TeamsMeetingCandidate
          //   locator={updateData?.meetingLink}
          token={acsToken}
          userId={{
            communicationUserId: "",
          }}
          displayName={"Candidate"}
          //   endpointUrl={"https://teams.microsoft.com/v2/?meetingjoin=true#/l/meetup-join/19:meeting_YjBkMzcwMGItZjkzZi00NTkyLWFmMjEtNmFmZTEyMzYzYzM1@thread.v2/0?context=%7b%22Tid%22%3a%22c1117dd5-465f-4ec6-86c2-c708d2e0ab15%22%2c%22Oid%22%3a%22971971b6-fc8c-47c1-a411-ed62ec162946%22%7d&anon=true&deeplinkId=bee86e90-0cbe-47e9-a486-4a7be80e2af8"}
          callInvitationURL={updateData?.meetingLink}
          locator={updateData?.meetingLink}
        />
      )}
    </div>
  );
};

export default CandidateMeeting;
