import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { message } from "antd";
import React, { useState, useEffect } from "react";
import apiClientServices, { setAuthorization } from "../../api-client";
import authService from "../../api-client/services/auth";
import {
  useApplicationContext,
  genRole,
  setUserToLocalStorage,
} from "../../context/application-context";
import { UserRole } from "../../submodules/Assessment-Platform-1.0-Dtos-FE/src/enums/userRole.enum";
import {
  generateCookiesKey,
  dropAuthCookies,
} from "../../submodules/Assessment-Platform-1.0-Dtos-FE/src/utils/context";
import { encryptText } from "../../utils/encrypt-decrypt";
import { msalConfig } from "../../utils/msal-config";
import { MicrosoftLogin } from "../microsoft-sign-in";
import { CreateUserCommand, IMSUserProfile } from "../../utils/login-helper";

interface LoginWithMsPropsType {
  afterLogin: (payload: any) => void;
}

const LoginWithMs = (_props: LoginWithMsPropsType) => {
  const { afterLogin } = _props;
  const { login, user, setUser, logout, tenantSettings, auth } =
    useApplicationContext();
  const msalInstance = new PublicClientApplication(msalConfig);

  const [isLoading, setIsLoading] = useState(true);

  const userHasRequiredRole =
    user && user.role && [UserRole.H_MANAGER].includes(user.role)
      ? true
      : false;

  const handleLogin = (loginData: IMSUserProfile) => {
    onMSLogin(loginData);
  };

  const onMSLogin = (payload: IMSUserProfile) => {
    const createUserCommand: CreateUserCommand = {
      email: payload.mail,
      familyName: payload.surname,
      givenName: payload.givenName,
      googleId: payload.id,
      name: payload.displayName,
    };

    postUserApi(createUserCommand);
  };

  const postUserApi = async (createUserCommand: CreateUserCommand) => {
    try {
      const response = await apiClientServices({
        method: "POST",
        url: "/connect/login",
        headers: {
          tId: tenantSettings.id,
        },
        data: {
          channelType: "Google",
          profileObj: createUserCommand,
        },
      });
      responseHandler(response, true);
    } catch (err) {
      setIsLoading(false);
      message.error("Login credential are invalid", 4);
      console.error(err);
    }
  };

  const responseHandler = async (response: any, isGoogleSignIn: boolean) => {
    if (response.data.role !== UserRole.STUDENT) {
      if (response.data.access_token) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("userid", response.data.user_id);
        localStorage.setItem("user-role", genRole(response.data.role!));
        const secureUserKey = generateCookiesKey(
          +response.data.user_id,
          +tenantSettings.id
        );
        dropAuthCookies(`ALT${secureUserKey}`, Date.now().toString());

        await setAuthorization(response.data.access_token);
        await login(response.data);
        await getUserProgress(
          response.data.access_token,
          response.data.user_id,
          secureUserKey
        );

        afterLogin({});
        setTimeout(() => {
          logout();
        }, 18000000);
        setIsLoading(false);
      } else if (response.data.status === 600) {
        setIsLoading(false);
        message.error(response.data.message, 4);
      } else {
        setIsLoading(false);
        message.error(
          "Please check your email inbox for email verification and next steps.",
          4
        );
      }
    } else {
      message.error("Sorry, you are not authorized");
    }
  };

  const getUserProgress = async (
    token: string,
    userId: number,
    secureUserKey: string
  ) => {
    const response = await authService.getAuthenticatedUser(
      token,
      userId.toString()
    );
    let userData = response.data!;
    userData.role = genRole(userData.role!);

    if (userData) {
      setUser({
        ...user,
        role: genRole(userData.role!),
        id: userData.id,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phoneNumber,
        profileImage: userData.profileImage,
        overallProgress: userData.overallProgress,
        referralLink: userData.referralLink,
      });

      setUserToLocalStorage({
        ...user,
        role: genRole(userData.role!),
        id: userData.id,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phoneNumber,
        profileImage: userData.profileImage,
        overallProgress: userData.overallProgress,
        referralLink: userData.referralLink,
      });
      const encryptUserData = await encryptText(
        JSON.stringify(userData),
        process.env.REACT_APP_SECRET_AUTH_VALIDATE!
      );

      dropAuthCookies(`AA${secureUserKey}`, encryptUserData);
    }
  };

  useEffect(() => {
    if (auth.userExists && userHasRequiredRole) {
      afterLogin({});
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [auth.userExists, userHasRequiredRole, afterLogin]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MicrosoftLogin
        handleMicrosoftResponse={handleLogin}
        buttonText="Login with Microsoft"
      />
    </MsalProvider>
  );
};

export default LoginWithMs;
