import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  .spin-parent-height {
    min-height: 100%;
  }

  .ant-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    display: none;
    color: green;
    font-size: 0;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  .ant-spin-dot-item {
    background-color: red;
  }
  .ant-spin-dot-item {
    background-color: orange !important;
  }
`;
