import apiClientServices from "../..";
import {
  CourseMetaDataDto,
  IManualScoring,
} from "../../../components/manual-scoring-cons-public/interface";
import { CustomResponse } from "../tenant";

export interface UpdateManualScoringCommand {
  _id?: string;
  userId: number;
  hmId: number;
  stageId: number;
  courseId: number;
  workFlowId: number;
  courseMetaData: CourseMetaDataDto;
  score: IManualScoring;
  interviewId: number;
  attemptNum: number;
}

interface UpdateManualScoringResponse extends CustomResponse {
  data?: IManualScoring;
}

async function updateManualScoreForm(
  command: UpdateManualScoringCommand
): Promise<UpdateManualScoringResponse> {
  const url = "/hmsrvc/manual-feedback/create-manual-score";
  try {
    const response = await apiClientServices.post(url, command);

    if (response.data) {
      return {
        data: response.data.result,
        status: response.data.status,
      };
    } else {
      throw new Error(`Failed to update score: ${response}`);
    }
  } catch (error: unknown) {
    return {
      data: undefined,
      error: error,
    };
  }
}

const userAdminService = {
  updateManualScoreForm,
};

export default userAdminService;
