import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginWithMs from "../../components/login-with-ms";
import queryString from "query-string";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// export const ourTokenKey = "token";
export const metaDataParamsKey = "m_data";

const Middleware = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [metadata, setMetadata] = useState(null);
  const [isLoginDone, setIsLoginDone] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const afterLoginSuccess = () => {
    setIsLoginDone(true);
  };

  useEffect(() => {
    // Parse query parameters using query-string
    const { md, at } = queryString.parse(location.search);

    if (md) {
      try {
        const parsedMetadata = JSON.parse(md as any);
        sessionStorage.setItem(metaDataParamsKey, md as any);
        setMetadata(parsedMetadata);
      } catch (error) {
        console.error("Failed to parse metadata:", error);
      }
    }

    // Store access token
    setAccessToken(at as any);
    // localStorage.setItem(ourTokenKey, at as any);
  }, []);

  // if (!metadata) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      {isLoginDone ? (
        children
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginWithMs afterLogin={afterLoginSuccess} />
        </div>
      )}
    </>
  );
};

export default Middleware;
