import apiClientServices from "../..";

async function getAuthenticatedUser(
  token: string,
  userId: string
): Promise<any> {
  if (token && userId) {
    const url = "/useradmin/admin";
    try {
      const response = await apiClientServices.get(`${url}/me/${userId}`);

      if (response.data) {
        return {
          data: response.data.result,
          status: response.data.status,
          message: response.data.message,
        };
      } else {
        throw new Error(`Failed to fetch user profile: ${response}`);
      }
    } catch (error: any) {
      return {
        data: undefined,
        error: error,
      };
    }
  } else {
    return {
      notFound: "Something went wrong",
    };
  }
}

const authService = {
  getAuthenticatedUser,
};

export default authService;
