import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { useApplicationContext } from "../../context/application-context";
import IntgSetUp from "../../containers/integration/setup";
import { FourDotLoader } from "../loaders/four-dot-loader";
import { INTG_ENDPOINTS } from "../../utils/endpoints";
import { useRecoilValue } from "recoil";
import { IsIntgCalRecoil } from "../../atoms/global-state";
import CompanyLogo from "../company-logo";

const RefreshMSToken = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);

  const isIntgCalMs = useRecoilValue(IsIntgCalRecoil);

  const [getEventsGate, setGetEventsGate] = useState(false);

  const { user, tenantSettings } = useApplicationContext();

  const getAccToken = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${
          INTG_ENDPOINTS.GET_ACCESS_TOKEN
        }?userId=${user.id?.toString()}&tenantUrl=${window.location.origin}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response?.data?.status === 200) {
        localStorage.setItem("teams-token", response.data?.result?.accessToken);
        localStorage.setItem("cal-id", response.data?.result?.calenderId);
        localStorage.setItem("ms-id", response.data?.result?.msUserId);
        setGetEventsGate(true);
      } else {
        if (response?.data?.result === "User not found on DB..") {
          setUserNotFound(true);
        } else {
          setError(true);
          message.error("Something went wrong with calendar authentication");
        }
      }
    } catch (error) {
      setError(true);
      message.error("Something went wrong with the API call");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAccToken();
    };

    fetchData();
  }, [isIntgCalMs]); // Run only once on component mount

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FourDotLoader />
      </div>
    );
  }

  if (isIntgCalMs && getEventsGate) {
    return <>{children}</>;
  }

  if (userNotFound) {
    return (
      // <div
      //   style={{
      //     width: "100vw",
      //     height: "100vh",
      //     display: "flex",
      //     flexDirection: "column",
      //     alignItems: "center",
      //     justifyContent: "center",
      //   }}
      // >
      <IntgSetUp />
      // </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Something went wrong....Please refresh the page and try again.
      </div>
    );
  }

  return <>{children}</>;
};

export default RefreshMSToken;
