import { Button, Drawer, Result } from "antd";
import React, { useEffect, useState } from "react";
import { ManualScoringConstraintsPublic } from "../../../../components/manual-scoring-cons-public";
import { metaDataParamsKey } from "../../../middleware";
import { DecodeUpdateManualScoringPublic } from "../interface";
import { mapperMetaData } from "..";
import { MobileDrawerContainer } from "./style";

const overviewFixed: React.CSSProperties = {
  bottom: "35%",
  right: "-5px",
  position: "fixed",
  padding: "5px 10px",
  // opacity: "80%",
  // width: "",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "lightblue",
  cursor: "pointer",
  zIndex: 33,
};

const HmDrawerMobile = () => {
  const [isOpenOverviewDrawer, setIsOpenOverviewDrawer] = useState(false);
  const [updateData, setUpdateData] = useState<any>();
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  useEffect(() => {
    const updateDataLocal: DecodeUpdateManualScoringPublic = JSON.parse(
      sessionStorage.getItem(metaDataParamsKey)!
    );

    setUpdateData(mapperMetaData(updateDataLocal));

    const localData = localStorage.getItem(
      `${updateDataLocal.uid}${updateDataLocal.hid}${updateDataLocal.sid}`
    );

    if (localData === "true") {
      setIsFeedbackSubmitted(true);
    }
  }, []);

  const onCloseOverviewDrawer = () => {
    setIsOpenOverviewDrawer(false);
  };

  //   const handleClickOpen = () => {
  //     setIsOpenOverviewDrawer(true);
  //   };

  return (
    <MobileDrawerContainer>
      <Button
        style={overviewFixed}
        onClick={() => setIsOpenOverviewDrawer(true)}
      >
        {/* <InfoCircleTwoTone /> */}
        <div>FEEDBACK</div>
      </Button>
      <Drawer
        title="Overview"
        onClose={onCloseOverviewDrawer}
        open={isOpenOverviewDrawer}
        width={"92vw"}
        placement="right"
        style={{ padding: "0px !important" }}
      >
        <div>
          {isFeedbackSubmitted ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Result
                title="Your feedback back is already captured!"
                extra={
                  updateData?.redirectUrl && (
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => {
                        window.open(updateData?.redirectUrl, "_self");
                      }}
                    >
                      Login to check your response
                    </Button>
                  )
                }
              />
            </div>
          ) : (
            <ManualScoringConstraintsPublic
              record={updateData}
              onCloseOverviewDrawer={onCloseOverviewDrawer}
              isFeedbackSubmitted={isFeedbackSubmitted}
              setIsFeedbackSubmitted={setIsFeedbackSubmitted}
            />
          )}
        </div>
      </Drawer>
    </MobileDrawerContainer>
  );
};

export default HmDrawerMobile;
