// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap'); */
* {
  box-sizing: border-box;
  /* font-family: 'Quicksand', sans-serif; */
}
html {
  overflow: auto;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px; 
  background: rgb(223, 223, 223); 
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: rgb(120, 120, 120);
  border-radius: 10px;
}

body {
  margin: 0;
font-family: 'Roboto', sans-serif;
  
}

body > iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,iGAAiG;AAEjG;EACE,sBAAsB;EACtB,0CAA0C;AAC5C;AACA;EACE,cAAc;AAChB;AACA;EACE,UAAU;EACV,WAAW;EACX,8BAA8B;AAChC;AACA,6CAA6C;AAC7C;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX,iCAAiC;;AAEjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;aACW;AACb","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap'); */\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');\n* {\n  box-sizing: border-box;\n  /* font-family: 'Quicksand', sans-serif; */\n}\nhtml {\n  overflow: auto;\n}\n::-webkit-scrollbar {\n  width: 3px;\n  height: 3px; \n  background: rgb(223, 223, 223); \n}\n/* Optional: show position indicator in red */\n::-webkit-scrollbar-thumb {\n  background: rgb(120, 120, 120);\n  border-radius: 10px;\n}\n\nbody {\n  margin: 0;\nfont-family: 'Roboto', sans-serif;\n  \n}\n\nbody > iframe {\n  display: none;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
