import { LogoImage, LogoWrapper } from "./style";

interface CompanyLogoProps {
  brandLogo?: string;
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({ brandLogo }) => {
  return (
    <LogoWrapper>
      <LogoImage src={brandLogo} alt="Initializing..." />
    </LogoWrapper>
  );
};

export default CompanyLogo;
