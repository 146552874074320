import Cookies from "js-cookie";
import { TenantSettingsResponse } from "../../dtos/tenant/index.dto";

export const dropCookies = (fetchedTenantRes: TenantSettingsResponse) => {
  Cookies.set("login-bg-URL", fetchedTenantRes?.tenantConfig?.loginBgURL!);
  Cookies.set(
    "tenant-logo",
    fetchedTenantRes?.tenantConfig?.brandLogo?.darkBgLogo!
  );
};

export const appMetaSetup = (tenant: TenantSettingsResponse) => {
  const ogTitleTag = document.querySelector('meta[property="og:title"]');
  const ogDescriptionTag = document.querySelector(
    'meta[property="og:description"]'
  );
  const ogImageTag = document.querySelector('meta[property="og:image"]');

  if (ogTitleTag && ogDescriptionTag && ogImageTag) {
    ogTitleTag.setAttribute("content", tenant?.tenantConfig?.helmet?.title!);
    ogDescriptionTag.setAttribute(
      "content",
      tenant?.tenantConfig?.helmet?.description!
    );
    ogImageTag.setAttribute(
      "content",
      tenant?.tenantConfig?.brandLogo.darkBgLogo
    );
  }
};

export const dropAuthCookies = (key: string, encryptedData: string) => {
  // const domain = window.location.hostname.includes("localhost")
  //     ? "localhost"
  //     : ".skilliq.co";

  // Cookies.set(key, encryptedData, {
  //     expires: 1,
  //     domain: domain,
  //     secure: true,
  //     sameSite: "none",
  // });

  Cookies.set(key, encryptedData);
};

export const generateCookiesKey = (
  userId: number,
  tenantId: number
): string => {
  //Key for XOR operation
  const key: number = 42;

  // Perform XOR operation
  const transformedUserId: number = userId ^ key;
  const transformedTenantId: number = tenantId ^ key;

  // Convert the transformed number to a string
  const strNum: string =
    transformedTenantId.toString() + transformedUserId.toString();

  // Map digits to corresponding characters
  const charMapping: { [key: string]: string } = {
    "0": "P",
    "1": "S",
    "2": "I",
    "3": "D",
    "4": "A",
    "5": "B",
    "6": "C",
    "7": "E",
    "8": "F",
    "9": "G",
  };

  // Create the final string representation
  const resultString: string = strNum
    .split("")
    .map((char) => charMapping[char])
    .join("");

  return resultString;
};

export function isTimeDifferenceLessThan6Hours(
  timestamp1: number,
  timestamp2: number
): boolean {
  // Convert timestamps to Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = Math.abs(date1.getTime() - date2.getTime());

  // Calculate the time difference in hours
  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);

  // Check if the difference is less than 6 hours
  return timeDifferenceHours < 6;
}
