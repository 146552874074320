import styled from "styled-components";
import { ILoaderStyles, generateTheme } from "../progress-bar-loader";

export const FourDotLoaderContainer = styled.div<ILoaderStyles>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: ${(props) => props.flexDirection};
  background: ${(props) =>
    props.background ? props.background : "transparent"};

  .loader {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
  }

  .loader:before,
  .loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
  }
  .loader:before {
    animation: ball1 1s infinite;
    background-color: ${(props) =>
      props.primaryColor
        ? props.primaryColor
        : props.theme.colorPalette.primaryColor};
    box-shadow: 30px 0 0
      ${(props) =>
        props.primaryColor
          ? props.primaryColor
          : props.theme.colorPalette.primaryColor};
    margin-bottom: 10px;
  }
  .loader:after {
    animation: ball2 1s infinite;
    background-color: ${(props) =>
      props.primaryColor
        ? props.primaryColor
        : props.theme.colorPalette.primaryColor};
    box-shadow: 30px 0 0
      ${(props) =>
        props.secondaryColor
          ? props.secondaryColor
          : props.theme.colorPalette.secondaryColor};
  }

  .message-text {
    font-size: 18px;
    font-weight: 600;
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg) scale(0.8);
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% {
      -webkit-transform: rotate(360deg) scale(1.2);
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotate(720deg) scale(0.8);
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }

  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0
        ${(props) =>
          props.secondaryColor
            ? props.secondaryColor
            : props.theme.colorPalette.secondaryColor};
    }
    50% {
      box-shadow: 0 0 0
        ${(props) =>
          props.secondaryColor
            ? props.secondaryColor
            : props.theme.colorPalette.secondaryColor};
      margin-bottom: 0;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0
        ${(props) =>
          props.primaryColor
            ? props.primaryColor
            : props.theme.colorPalette.primaryColor};
      margin-bottom: 10px;
    }
  }

  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0
        ${(props) =>
          props.secondaryColor
            ? props.secondaryColor
            : props.theme.colorPalette.secondaryColor};
    }
    50% {
      box-shadow: 0 0 0
        ${(props) =>
          props.secondaryColor
            ? props.secondaryColor
            : props.theme.colorPalette.secondaryColor};
      margin-top: -20px;
      -webkit-transform: translate(15px, 15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0
        ${(props) =>
          props.primaryColor
            ? props.primaryColor
            : props.theme.colorPalette.primaryColor};
      margin-top: 0;
    }
  }
`;
