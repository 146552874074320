import React, { ReactNode, useEffect, useState } from "react";
import { IntgRowContainer, IntgSetupContainer } from "./style";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  GoogleOutlined,
  WindowsOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { Avatar, Button, Card, Modal, message } from "antd";
import { useApplicationContext } from "../../../context/application-context";
import { openPopup } from "../helper";
import CalenderListModal from "./calender-list-modal";
import axios from "axios";
import {
  CalendarListItem,
  IntgOrgEnum,
  MsIntgReturnPayload,
} from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/intg/ms-intg";
import { CalendarItemDto } from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/calendar.dto";
import { INTG_ENDPOINTS } from "../../../utils/endpoints";
import { FourDotLoader } from "../../../components/loaders/four-dot-loader";
import { useRecoilState } from "recoil";
import { IsIntgCalRecoil } from "../../../atoms/global-state";
import { isMobileWidth } from "../../../app-routes";
// import { useRecoilState, useSetRecoilState } from "recoil";
// import { IsIntgCalRecoil } from "../../../atoms/global-state";

const { Meta } = Card;

export interface IntgRowProps {
  imageURL: string;
  title: string;
  desc: string;
  avatar: ReactNode;
  isIntg: boolean;
  loginUrl: string;
  callGetAllIntgApi: any;
}

const IntgRow = (props: IntgRowProps) => {
  const { user, tenantSettings } = useApplicationContext();
  const colorPalette = tenantSettings.tenantConfig.colorPalette;
  const { imageURL, title, desc, avatar, isIntg, loginUrl, callGetAllIntgApi } =
    props;

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [isLoadingOkButton, setIsLoadingOkButton] = useState(false);
  const [isIntgGlobal, setIsIntgMs] = useRecoilState(IsIntgCalRecoil);

  const handleGetToken = (token: string) => {
    if (token) {
      localStorage.setItem("teams-token", token);
      setIsIntgMs(true);

      // openCloseEditModal(true);
    } else {
      message.error("Something went wrong....Please integrate again.....", 10);
    }
  };

  const handleClickIntg = () => {
    openPopup(loginUrl, handleGetToken);
    openCloseEditModal(false);
  };

  const saveCalenderId = async (calendarItem: CalendarListItem) => {
    setIsLoadingOkButton(true);

    try {
      const payload = {
        calendarId: calendarItem.id!,
        userId: user.id,
      };

      const response = await axios({
        method: "POST",
        url: `${INTG_ENDPOINTS.SAVE_CALENDAR_ID}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: payload,
      });

      if (response.data.status === 200) {
        setIsLoadingOkButton(false);

        message.success("Calender saved successfully!!");

        setIsIntgMs(true);

        setIsOpenEditModal(false);

        callGetAllIntgApi();

        return response.data?.result;
      } else return null;
    } catch (er) {
      setIsOpenEditModal(false);

      setIsLoadingOkButton(false);

      message.error("Something wrong ....Please try again", 5);
    }
  };

  const openCloseEditModal = (value: boolean) => {
    if (isLoadingOkButton) {
      message.warning("Please wait, we are processing your request!");
      return;
    }
    setIsOpenEditModal(value);
  };

  const handleClickEdit = () => {
    openCloseEditModal(true);
  };

  const openCloseDeleteModal = (value: boolean) => {
    setIsOpenDeleteModal(value);
  };

  const handleClickDelete = async () => {
    await deleteIntg(false);
  };

  const deleteIntg = async (value: boolean) => {
    setIsLoadingOkButton(true);

    try {
      const payload = {
        isIntg: value,
        userId: user.id,
      };

      const response = await axios({
        method: "POST",
        url: `${INTG_ENDPOINTS.TOGGLE_INTG}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: payload,
      });

      if (response.data.status === 200) {
        setIsLoadingOkButton(false);

        message.success("Deleted successfully!!");

        setIsOpenEditModal(false);
        openCloseDeleteModal(false);

        callGetAllIntgApi();

        return response.data?.result;
      } else return null;
    } catch (er) {
      setIsOpenEditModal(false);

      setIsLoadingOkButton(false);

      message.error("Something wrong ....Please try again", 5);

      return null;
    }
  };

  const isIntgCheck = () => {
    if (isIntg) {
      setIsIntgMs(true);
      return [
        <EditFilled onClick={handleClickEdit} />,
        <DeleteFilled onClick={() => openCloseDeleteModal(true)} />,
      ];
    } else {
      return [
        <div
          style={{
            background: colorPalette.primaryColor,
            color: "white",
            height: "100%",
          }}
          onClick={() => handleClickIntg()}
        >
          Integrate
        </div>,
      ];
    }
  };

  return (
    <IntgRowContainer isIntg={isIntg}>
      <Card
        style={{ width: isMobileWidth ? "100%" : 450 }}
        className="card-wrapper"
        cover={
          <img
            style={{ padding: "50px", minWidth: "100%", height: "auto" }}
            alt="logo"
            src={imageURL}
          />
        }
        actions={isIntgCheck()}
      >
        <Meta avatar={avatar} title={title} description={desc} />
      </Card>

      {isOpenEditModal && (
        <CalenderListModal
          visible={isOpenEditModal}
          onClose={() => openCloseEditModal(false)}
          onOk={saveCalenderId}
          isLoadingProp={isLoadingOkButton}
        />
      )}

      {isOpenDeleteModal && (
        <Modal
          title="Confirmation"
          open={isOpenDeleteModal}
          destroyOnClose={true}
          onCancel={() => openCloseDeleteModal(false)}
          // onOk={() => {
          //   // Handle OK button click, if needed
          //   onClickOk(selectedItem!);
          // }}
          footer={[
            <Button
              key="cancel"
              onClick={() => openCloseDeleteModal(false)}
              style={{
                borderColor: colorPalette.primaryColor,
                color: colorPalette.primaryColor,
              }}
            >
              Cancel
            </Button>,
            <Button
              loading={isLoadingOkButton}
              key="ok"
              type="primary"
              onClick={() => handleClickDelete()}
              style={{
                background: colorPalette.primaryColor,
                color: "white",
                border: "none",
              }}
            >
              OK
            </Button>,
          ]}
        >
          Are you sure u want to delete the integration?
        </Modal>
      )}
    </IntgRowContainer>
  );
};

const IntgSetUp = () => {
  const [intgDetails, setIntgDetails] = useState<MsIntgReturnPayload>();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useApplicationContext();

  useEffect(() => {
    if (user.id) {
      getAllIntgApi();
    }
  }, [user]);

  const getAllIntgApi = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: "GET",
        url: `${
          INTG_ENDPOINTS.GET_ALL_INTG
        }?userId=${user.id!.toString()}&tenantUrl=${window.location.origin}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response && response?.data && response.data.status === 200) {
        setIntgDetails(response.data?.result);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      message.error("Something went wrong!!!");
    }
  };

  return (
    <IntgSetupContainer>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FourDotLoader />
        </div>
      ) : (
        intgDetails && (
          <div className="ntg-main-internal-wrapper">
            <IntgRow
              imageURL="https://public-upload-prod.s3.ap-south-1.amazonaws.com/ms-teams-3.png"
              title="MS Teams"
              desc=""
              avatar={<WindowsOutlined />}
              isIntg={intgDetails[IntgOrgEnum.MS].isIntg}
              loginUrl={intgDetails[IntgOrgEnum.MS].loginUrl!}
              // loginUrl="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=45a75b39-5c67-48a2-8482-eaf4505c6eea&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3010%2Fms-login&response_mode=query&scope=Calendars.ReadWrite+User.Read+openid+offline_access&state=state"
              callGetAllIntgApi={getAllIntgApi}
            />
          </div>
        )
      )}
    </IntgSetupContainer>
  );
};

export default IntgSetUp;
