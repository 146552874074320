import styled from "styled-components";

export const ManualScoringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;

  /* height: calc(100vh - 115px); */
  text-align: left;
  .ms-instruction {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    font-size: 13px;
    .ms-overall-score {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 270px;
      margin-bottom: 10px;
      .individual-q-score {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        width: 100%;
      }
      .ant-btn {
        position: fixed;
        top: 10px;
        right: 24px;
      }
    }
  }

  .ms-questions-wrapper {
    display: inline-flex;

    flex-direction: column;
    height: calc(100% - 188px);
    /* @media (max-width: 900px) { */
    width: 90% height 90%;
    /* } */
    overflow: auto;
    .ms-questions-container {
      display: flex;
      /* @media (max-width: 900px) { */
      flex-direction: column;
      /* } */
      justify-content: space-between;
      gap: 10px;
      .left {
        .selected-rating {
          border: 2px solid;
          padding: 3px;
        }
        .score-1 {
          border-color: ${(props) => props.theme.colorPalette.dangerColor};
        }
        .score-2 {
          border-color: #ff914d;
        }
        .score-3 {
          border-color: ${(props) => props.theme.colorPalette.darkGreen};
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        /* width: 50vw; */
        /* @media (max-width: 900px) { */
        width: 100%;
        /* } */
        gap: 20px;

        .right-1 {
          height: 30%;
          /* text-align: right; */
          .ant-select {
            width: 100%;
          }
        }
        .right-2 {
          height: 100%;
          width: 100%;
          /* text-align: right; */
        }
      }
    }
  }
`;
