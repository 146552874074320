import styled from "styled-components";

export const ProgressBarLoaderContainer = styled.div`
  width: 80px;
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(
      ${(props) => props.theme.colorPalette.primaryColor} 0 0
    )
    left/0% 100% no-repeat ${(props) => props.theme.colorPalette.secondaryColor};
  animation: p2 0.8s infinite steps(60);

  @keyframes p2 {
    100% {
      background-size: 110% 100%;
    }
  }
`;
