import apiClientServices from "../..";
import { TenantSettingsResponse } from "../../../submodules/Assessment-Platform-1.0-Dtos-FE/src/dtos/tenant/index.dto";

export type CustomResponse = {
  message?: string;
  status?: number;
  error?: any;
};

const getUserServiceUrl = () => {
  return "/usr-srvc";
};

export interface GetTenantSettingsResponse extends CustomResponse {
  data?: TenantSettingsResponse;
}

async function getTenantSettings(
  tenantId: number
): Promise<GetTenantSettingsResponse> {
  try {
    const response = await apiClientServices.get(`/tenant`, {
      params: {
        tenantId: tenantId,
      },
    });

    if (response.data?.result) {
      return {
        data: response.data?.result,
        status: response.status,
      };
    } else {
      throw new Error(`Failed to fetch user profile: ${response}`);
    }
  } catch (error: unknown) {
    return {
      data: undefined,
      error: error,
    };
  }
}

const tenantService = {
  getTenantSettings,
};

export default tenantService;
