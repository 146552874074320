import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  message,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useRef, useState, useEffect } from "react";

import axios from "axios";
import { useApplicationContext } from "../../context/application-context";
import {
  IManualScoringProps,
  IManualScoring,
  ManualCategoryType,
  UpdateManualScoringPublic,
  LoadingConfig,
  defaultLoadingConfig,
} from "./interface";
import { MANAGER_ENDPOINTS } from "../../utils/endpoints";
import userAdminService, {
  UpdateManualScoringCommand,
} from "../../api-client/services/user-admin";
import { isMobileWidth } from "../../app-routes";
import { feature_feedback_score_enum } from "../../utils/feature-factory";
import { Loading } from "../Loading";
import { ManualScoringWrapper } from "./style";
import { metaDataParamsKey } from "../../containers/middleware";

const { Panel } = Collapse;

const { TextArea } = Input;

export enum FeedBackScoreEnum {
  SELECTED = "Selected",
  REJECTED = "Rejected",
  ON_HOLD = "On Hold",
}

export const genColor = (value: number) => {
  if (value === 3) {
    return { background: "darkgreen", color: "#00b300" };
  } else if (value === 2) {
    return { background: "#8B8000", color: "yellow" };
  } else if (value === 1) {
    return { background: "#640000", color: "red" };
  } else return { background: "", color: "" };
};

export const renderSelectScore = (valueParam: number | string) => {
  let value = Math.round(valueParam as number);

  if (value == 1) {
    return FeedBackScoreEnum.REJECTED;
  } else if (value == 2) {
    return FeedBackScoreEnum.ON_HOLD;
  } else if (value == 3) {
    return FeedBackScoreEnum.SELECTED;
  } else return value;
};

export const ManualScoringConstraintsPublic: React.FC<IManualScoringProps> = (
  _props
) => {
  const { tenantSettings } = useApplicationContext();

  const {
    record,
    isFeedbackSubmitted,
    setIsFeedbackSubmitted,
    onCloseOverviewDrawer,
  } = _props;
  const cp = tenantSettings.tenantConfig.colorPalette;
  let totalQuestion = 0;
  // let tempTotalQuestion = 0;
  const [position, setPosition] = useState({ x: 5, y: 70 });

  const refArray = useRef<Array<HTMLDivElement | null>>(Array(100).fill(null));

  const [manualScoreForm, setManualScoreForm] = useState<IManualScoring>();
  const [loadingConfig, setLoadingConfig] =
    useState<LoadingConfig>(defaultLoadingConfig);

  const [isModalFeedbackVisible, setIsModalFeedbackVisible] = useState(false);
  const [isOpenOverviewDrawer, setIsOpenOverviewDrawer] = useState(false);

  useEffect(() => {
    getManualScoreTemplate();
  }, []);

  const getManualScoreTemplate = async () => {
    setLoadingConfig({
      isLoading: true,
      tip: "Fetching Template",
    });

    const response = await axios({
      method: "GET",
      url: `${MANAGER_ENDPOINTS.GET_MANUAL_TEMPLATE}/${record.courseId}/${record.stageId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (response?.data?.result) {
      setLoadingConfig(defaultLoadingConfig);
      setManualScoreForm(response.data?.result!);
    } else {
      setLoadingConfig(defaultLoadingConfig);
      message.error("Something went wrong");
    }
  };

  const smoothScrollTo = (categoryIndex: number, questionIndex: number) => {
    setIsOpenOverviewDrawer(false);
    setTimeout(() => {
      const targetElement =
        refArray.current[categoryIndex * 100 + questionIndex]; // Get target element using ref
      targetElement?.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to target element
      // setIsModalFeedbackVisible(true);
    }, 600);
  };

  const handleSaveClick = () => {
    // setIsOpenOverviewDrawer(true);
    // return;
    if (manualScoreForm?.category && manualScoreForm.category.length > 0) {
      let scoredQuestionsCount = 0;
      manualScoreForm.category.forEach((category) => {
        scoredQuestionsCount += category.questions.filter((question) => {
          if (category.type != ManualCategoryType.FINAL_FEEDBACK)
            return question.gainedMarks;
        }).length;
      });

      const constraints = manualScoreForm.instruction.constraints!;

      if (
        scoredQuestionsCount ===
        constraints?.categoryNumber * constraints.minQuestionNumber
      ) {
        let isValid = false;
        manualScoreForm?.category?.map((item) => {
          if (item.type === ManualCategoryType.FINAL_FEEDBACK) {
            isValid = true;
            return;
          } else {
            isValid = false;
          }
        });

        // const questionArray = handleCheckComment();

        // if (questionArray.length > 0) {
        //   showCommentErrorModal(questionArray);
        //   return;
        // }
        if (isValid) setIsModalFeedbackVisible(true);
        else showConfirmModal();
      } else {
        showErrorModal();
      }
    } else {
      showErrorModal();
    }
  };

  const handleCheckComment = () => {
    const questionNumbers: number[] = [];

    let index = 0;

    manualScoreForm?.category.map((category) => {
      category.questions.map((question, questionIndex) => {
        if (
          question?.gainedMarks! > 0 &&
          (!question.comment || question.comment === " ")
        ) {
          questionNumbers.push(index + 1);
        }
        index += 1;
      });
    });

    return questionNumbers;
  };

  const showConfirmModal = () => {
    Modal.confirm({
      title: "Are you sure you want to proceed?",
      content: (
        <>
          <p>
            You are about to save your manual scoring. Please review your scores
            carefully before saving. Once saved, your scoring cannot be
            modified.
          </p>
          <p>
            Click "Confirm" to save your scores, or "Cancel" to review and make
            any necessary changes.
          </p>
        </>
      ),
      okText: "Confirm",
      onOk: handleSave,
    });
  };

  const handleSaveFinalFeedback = () => {
    let isValid = false;
    manualScoreForm?.category?.map((item) => {
      if (
        item.type === ManualCategoryType.FINAL_FEEDBACK &&
        item.questions[0]?.gainedMarks != 0
      ) {
        if (item.questions[0]?.gainedMarks == 3) {
          isValid = true;
        } else {
          if (item?.questions[0]?.comment === " ") {
            isValid = false;
          } else isValid = true;
        }
      } else {
        isValid = false;
      }
    });
    if (isValid) {
      showConfirmModal();
    } else {
      message.warning("Please provide feedback!");
    }
  };

  const showErrorModal = () => {
    Modal.error({
      title: "Incomplete Scoring",
      content: (
        <p>
          You have not scored{" "}
          {manualScoreForm?.instruction.constraints?.minQuestionNumber}{" "}
          questions in each category. Please ensure that you have scored at
          least three questions in each category before saving.
        </p>
      ),
    });
  };

  const showCommentErrorModal = (questionNumbers: number[]) => {
    Modal.error({
      title: "Incomplete Comments!",
      content: (
        <p>
          Comments are incomplete for the following questions:{" "}
          {questionNumbers.map((item) => {
            return <span> Q{item} </span>;
          })}
        </p>
      ),
    });
  };

  const handleCommand = async (
    record: UpdateManualScoringPublic,
    manualScoreForm: IManualScoring
  ) => {
    let command: UpdateManualScoringCommand = {
      ...(manualScoreForm._isExist && {
        _id: manualScoreForm._id,
      }),
      userId: record.userId,
      hmId: +record.hmId,
      stageId: record.stageId,
      courseId: record.courseId,
      workFlowId: record.workFlowId,
      interviewId: record.interviewId,
      attemptNum: record.attemptNum,
      courseMetaData: {
        courseName: record?.courseMetaData?.courseName!,
        stageName: record?.courseMetaData?.stageName!,
      },
      score: manualScoreForm,
    };

    return command;
  };

  const handleSave = async () => {
    setLoadingConfig({
      isLoading: true,
      tip: "Fetching Score Form",
    });

    let command = await handleCommand(record, manualScoreForm!);

    const response = await userAdminService.updateManualScoreForm(command);

    if (response.status === 200) {
      setLoadingConfig(defaultLoadingConfig);
      message.success("Updated score successfully");
      localStorage.setItem(
        `${record.userId}${record.hmId}${record.stageId}`,
        "true"
      );

      setIsModalFeedbackVisible(false);

      setIsFeedbackSubmitted(true);
      setIsOpenOverviewDrawer(false);

      if (onCloseOverviewDrawer) {
        onCloseOverviewDrawer();
      }

      // window.location.reload();
    } else {
      setLoadingConfig(defaultLoadingConfig);
      message.error("Something went wrong");
    }
  };

  const handleSelectClick = (categoryIndex: number, questionIndex: number) => {
    const category = manualScoreForm?.category[categoryIndex];
    const maxQuestions =
      manualScoreForm?.instruction?.constraints?.minQuestionNumber || 3;
    const gainedMarks = category?.questions[questionIndex].gainedMarks;

    // Check if the user has already scored the maximum number of questions
    const scoredQuestionsCount: any = category?.questions.filter(
      (question) => question.gainedMarks
    ).length;

    if (scoredQuestionsCount >= maxQuestions && !gainedMarks) {
      warning(category?.title!);
      return false;
    }

    return true;
  };

  const warning = (categoryTitle: string) => {
    Modal.warning({
      title: `${categoryTitle} Category`,
      content: (
        <div>
          <p>
            You have already scored the maximum number of questions in this
            category.
          </p>
          <p>
            If you want to score a new question, you need to remove the score
            from one of the existing questions.
          </p>
        </div>
      ),
    });
  };

  const handleScoreChange = (
    categoryIndex: number,
    questionIndex: number,
    selectedScore: any,
    isRadioButton?: boolean
  ) => {
    const check = handleSelectClick(categoryIndex, questionIndex);
    if (check) {
      if (!selectedScore) {
        selectedScore = 0;
      }
      let updatedManualScoreForm: any = { ...manualScoreForm };
      const prevScore =
        updatedManualScoreForm?.category[categoryIndex]?.questions[
          questionIndex
        ].gainedMarks;
      updatedManualScoreForm.category[categoryIndex].questions[
        questionIndex
      ].gainedMarks = selectedScore;

      if (
        updatedManualScoreForm.category[categoryIndex].id?.includes("final")
      ) {
        // eat 5star, do nothing
      } else {
        if (
          typeof prevScore === "number" &&
          typeof selectedScore === "undefined"
        ) {
          updatedManualScoreForm.overallScore -= prevScore;
        } else {
          updatedManualScoreForm.overallScore +=
            selectedScore - (prevScore || 0);
        }
      }

      setManualScoreForm(updatedManualScoreForm);
    }
  };

  const handleCommentChange = (
    categoryIndex: number,
    questionIndex: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const comment = event.target.value;
    let updatedManualScoreForm: any = { ...manualScoreForm };

    if (
      manualScoreForm &&
      updatedManualScoreForm.category[categoryIndex].questions[questionIndex]
        .gainedMarks > 0
    ) {
      updatedManualScoreForm.category[categoryIndex].questions[
        questionIndex
      ].comment = comment;
      setManualScoreForm(updatedManualScoreForm);
    } else {
      message.warning("Please provide rating before giving the comment!");
    }
  };

  const genButtonColors = (gainedMarks: any, itemMark: any) => {
    if (gainedMarks === itemMark) {
      return { color: "white", background: cp.primaryColor };
    } else return { color: "", background: "" };
  };

  const onCloseOverviewDrawerOri = () => {
    setIsOpenOverviewDrawer(false);
  };

  const handleClickOpen = () => {
    setIsOpenOverviewDrawer(true);
  };

  const overviewFixed: React.CSSProperties = {
    top: "70px",
    left: "0px",
    position: "fixed",
    padding: "5px 10px",
    // opacity: "80%",
    // width: "",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: "lightblue",
    cursor: "pointer",
    zIndex: 33,
  };

  const handleRenderQuestions = () => {
    let tempTotalQuestion = 0;

    return (
      manualScoreForm?.category &&
      manualScoreForm.category.length > 0 &&
      manualScoreForm.category.map((category, categoryIndex) => {
        if (category.type !== ManualCategoryType.FINAL_FEEDBACK)
          return (
            <div>
              <div>Category {categoryIndex + 1}</div>
              <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
                {category.questions &&
                  category.questions.length > 0 &&
                  category.questions.map((question, questionIndex) => {
                    tempTotalQuestion += 1;
                    if (
                      question.gainedMarks &&
                      category.type !== ManualCategoryType.FINAL_FEEDBACK
                    ) {
                      return (
                        <a
                          style={{
                            border: "1px solid gray",
                            borderRadius: "4px",
                            padding: "3px 5px",
                          }}
                          key={`cr-${categoryIndex}-q-${questionIndex}`}
                          onClick={() =>
                            smoothScrollTo(categoryIndex, questionIndex)
                          }
                          href={`#cr-${categoryIndex}-q-${questionIndex}`}
                        >
                          Q {tempTotalQuestion} : {question.gainedMarks}
                        </a>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
              <Divider type="horizontal" />
            </div>
          );
        else return null;
      })
    );
  };

  const handleRenderDesktop = () => {
    let tempTotalQuestion = 0;

    return (
      manualScoreForm?.category &&
      manualScoreForm.category.length > 0 &&
      manualScoreForm.category.map((category, categoryIndex) => {
        return (
          category.questions &&
          category.questions.length > 0 &&
          category.questions.map((question, questionIndex) => {
            tempTotalQuestion += 1;
            if (
              question.gainedMarks &&
              category.type !== ManualCategoryType.FINAL_FEEDBACK
            ) {
              return (
                <a
                  key={`c-${categoryIndex}-q-${questionIndex}`}
                  onClick={() => smoothScrollTo(categoryIndex, questionIndex)}
                  href={`#c-${categoryIndex}-q-${questionIndex}`}
                >
                  Q {tempTotalQuestion} : {question.gainedMarks}
                  <Divider type="vertical" />
                </a>
              );
            } else {
              return null;
            }
          })
        );
      })
    );
  };

  return (
    <Loading
      spinning={loadingConfig.isLoading}
      tip={loadingConfig.tip}
      style={{ height: "100vh" }}
    >
      <ManualScoringWrapper style={{ position: "relative" }}>
        {/* {isMobileWidth && ( */}
        <div
          style={overviewFixed}
          onClick={() => setIsOpenOverviewDrawer(true)}
        >
          <InfoCircleTwoTone />
        </div>
        {/* )} */}
        <div
          style={{
            // width: "100vw",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "uppercase",
            color: cp.primaryColor,
          }}
        >
          {record.candidateName} | {record?.courseMetaData?.courseName} -{" "}
          {record?.courseMetaData?.stageName}
        </div>
        <div className="ms-instruction">
          <div>
            {manualScoreForm?.instruction?.title && (
              <p>
                <b>{manualScoreForm.instruction.title}</b>
              </p>
            )}
            {manualScoreForm?.instruction?.ratingCriteria &&
              manualScoreForm?.instruction?.ratingCriteria.length > 0 && (
                <>
                  <p>Rating Analysis: </p>
                  {manualScoreForm?.instruction?.ratingCriteria.map(
                    (rating) => {
                      return (
                        <p>
                          {rating.score}: {rating.criteria}
                        </p>
                      );
                    }
                  )}
                </>
              )}
          </div>
          <div className="ms-overall-score">
            {manualScoreForm?.overallScore && (
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "fold",
                  width: "100%",
                  textAlign: "center",
                  border: "1px solid",
                  borderRadius: "10px",
                  padding: "10px 5px",
                }}
              >
                Overall Score:{" "}
                {manualScoreForm?.overallScore == 0
                  ? ""
                  : manualScoreForm?.overallScore || ""}
              </div>
            )}

            {!isMobileWidth && (
              <div className="individual-q-score">{handleRenderDesktop()}</div>
            )}
          </div>
        </div>
        <div className="ms-questions-wrapper">
          {manualScoreForm?.category &&
            manualScoreForm?.category.length > 0 &&
            manualScoreForm?.category.map((category, categoryIndex) => {
              if (category.type !== ManualCategoryType.FINAL_FEEDBACK)
                return (
                  <>
                    <Collapse>
                      <Panel
                        style={{
                          color: cp.primaryColor,
                        }}
                        header={
                          <div style={{ color: cp.primaryColor }}>
                            <div>
                              <div>
                                <u
                                  style={{
                                    color: cp.primaryColor,
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {category?.title}
                                </u>
                              </div>
                            </div>
                            {/* </Divider> */}
                            <i>
                              {category?.description && (
                                <p>{category?.description}</p>
                              )}
                            </i>
                          </div>
                        }
                        key={categoryIndex + 1}
                      >
                        {category.questions &&
                          category.questions.length > 0 &&
                          category.questions.map((question, questionIndex) => {
                            totalQuestion += 1;

                            return (
                              <div
                                key={questionIndex}
                                className="ms-questions-container"
                                ref={(element) =>
                                  (refArray.current[
                                    categoryIndex * 100 + questionIndex
                                  ] = element)
                                }
                              >
                                <div className="left">
                                  <Divider orientation="left">
                                    Question {totalQuestion}
                                  </Divider>
                                  <p
                                    id={`#c-${categoryIndex}-q-${questionIndex}`}
                                  >
                                    <b>{question?.pQuestion} </b>
                                    <br />
                                    {question?.lQuestion && (
                                      <i>({question?.lQuestion})</i>
                                    )}
                                  </p>
                                  <Collapse
                                  // defaultActiveKey={["1"]}
                                  // onChange={onChange}
                                  >
                                    <Panel
                                      header={<div>Rating Analysis </div>}
                                      key={questionIndex}
                                    >
                                      {question?.ratingCriteria &&
                                        question?.ratingCriteria.length > 0 &&
                                        question?.ratingCriteria.map(
                                          (rating) => {
                                            return (
                                              <p
                                                className={
                                                  rating.score ===
                                                  question.gainedMarks
                                                    ? `selected-rating score-${question.gainedMarks}`
                                                    : ""
                                                }
                                              >
                                                {rating.score}:{" "}
                                                {rating.criteria}
                                              </p>
                                            );
                                          }
                                        )}
                                    </Panel>
                                  </Collapse>
                                </div>
                                <div className="right">
                                  {question?.gainedMarks! >= 0 && (
                                    <div className="right-1">
                                      {/* <p>{question.isEditable && "Select Rating"}</p> */}
                                      {question?.ratingCriteria &&
                                        question?.ratingCriteria.length > 0 && (
                                          <div>
                                            <Radio.Group
                                              style={{ display: "flex" }}
                                              value={"large"}
                                              onChange={(selectedValue) =>
                                                handleScoreChange(
                                                  categoryIndex,
                                                  questionIndex,
                                                  selectedValue.target.value,
                                                  true
                                                )
                                              }
                                            >
                                              {question?.ratingCriteria.map(
                                                (rating) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        padding: "10px 20px",
                                                      }}
                                                    >
                                                      <Radio.Button
                                                        value={rating.score}
                                                        type={"primary"}
                                                        style={{
                                                          background:
                                                            genButtonColors(
                                                              question.gainedMarks,
                                                              rating.score
                                                            ).background,
                                                          color:
                                                            genButtonColors(
                                                              question.gainedMarks,
                                                              rating.score
                                                            ).color,
                                                        }}
                                                        // type={
                                                        //   rating.score ===
                                                        //   question.gainedMarks
                                                        //     ? "primary"
                                                        //     : "default"
                                                        // }
                                                      >
                                                        {feature_feedback_score_enum.includes(
                                                          tenantSettings.tenantName
                                                        )
                                                          ? renderSelectScore(
                                                              rating.score
                                                            )
                                                          : rating.score}
                                                      </Radio.Button>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </Radio.Group>
                                          </div>
                                        )}
                                    </div>
                                  )}

                                  <div className="right-2">
                                    <div style={{ position: "relative" }}>
                                      <TextArea
                                        style={{
                                          borderRadius: "10px",
                                          padding: "30px 10px 10px 10px",
                                        }}
                                        rows={7}
                                        placeholder="Please provide your opinion here"
                                        value={question.comment}
                                        onChange={(selectedValue) =>
                                          handleCommentChange(
                                            categoryIndex,
                                            questionIndex,
                                            selectedValue
                                          )
                                        }
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0px",
                                          background: cp.primaryColor,
                                          color: "white",
                                          width: "100%",
                                          borderTopLeftRadius: "6px",
                                          borderTopRightRadius: "6px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Please provide your opinion below
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Panel>
                    </Collapse>
                  </>
                );
            })}
        </div>

        <div
          style={{ width: "100%", textAlign: "center", padding: "20px 20px" }}
        >
          {/* <GenericButton
              // width="140px"
              style={{ width: "140px" }}
              text="Save"
              onClick={handleSaveClick}
            /> */}

          <Button
            onClick={handleSaveClick}
            style={{
              background: cp.primaryColor,
              color: "white",
            }}
          >
            Click to Complete Interview
          </Button>
        </div>

        <Modal
          title="Overall Feedback"
          open={isModalFeedbackVisible}
          // open={true}
          onOk={handleSaveFinalFeedback}
          onCancel={() => setIsModalFeedbackVisible(false)}
          // okText="Done"
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsModalFeedbackVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleSaveFinalFeedback}
              style={{
                backgroundColor:
                  tenantSettings.tenantConfig.colorPalette.primaryColor,
                borderColor:
                  tenantSettings.tenantConfig.colorPalette.primaryColor,
              }}
            >
              Done
            </Button>,
          ]}
        >
          {manualScoreForm?.category?.map((item, categoryIndex) => {
            if (item.type === ManualCategoryType.FINAL_FEEDBACK) {
              return item.questions.map((innerItem, questionIndex) => (
                <div
                  key={`${categoryIndex}-${questionIndex}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>{innerItem.pQuestion}</div>
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) =>
                      handleScoreChange(
                        categoryIndex,
                        questionIndex,
                        e.target.value
                      )
                    }
                    value={innerItem.gainedMarks}
                  >
                    <Space direction="vertical">
                      <Radio
                        key={`questionIndex_1`}
                        id={`questionIndex_1`}
                        value={3}
                        style={{
                          color:
                            innerItem.gainedMarks === 3 ? "green" : "black",
                        }}
                      >
                        SELECT
                      </Radio>
                      <Radio
                        key={`questionIndex_2`}
                        id={`questionIndex_2`}
                        value={2}
                        style={{
                          color:
                            innerItem.gainedMarks === 2 ? "#8B8000" : "black",
                        }}
                      >
                        ON HOLD
                      </Radio>
                      <Radio
                        key={`questionIndex_3`}
                        id={`questionIndex_3`}
                        value={1}
                        style={{
                          color: innerItem.gainedMarks === 1 ? "red" : "black",
                        }}
                      >
                        REJECT
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <TextArea
                    style={{
                      borderRadius: "10px",
                      borderColor: genColor(innerItem.gainedMarks!).background,
                    }}
                    rows={7}
                    placeholder="Please provide your opinion here"
                    value={innerItem.comment}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleCommentChange(categoryIndex, questionIndex, e)
                    }
                  />
                </div>
              ));
            }
            return null;
          })}
        </Modal>

        {/* <Modal onCancel={onCloseOverviewDrawerOri} open={isOpenOverviewDrawer}>
          <div>
            {manualScoreForm?.category &&
              manualScoreForm.category.length > 0 &&
              manualScoreForm.category.map((category, categoryIndex) => {
                if (category.type !== ManualCategoryType.FINAL_FEEDBACK)
                  return (
                    <div>
                      <div>Category {categoryIndex + 1}</div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {category.questions &&
                          category.questions.length > 0 &&
                          category.questions.map((question, questionIndex) => {
                            tempTotalQuestion += 1;
                            if (
                              question.gainedMarks &&
                              category.type !==
                                ManualCategoryType.FINAL_FEEDBACK
                            ) {
                              return (
                                <a
                                  key={`cr-${categoryIndex}-q-${questionIndex}`}
                                  onClick={() =>
                                    smoothScrollTo(categoryIndex, questionIndex)
                                  }
                                  href={`#cr-${categoryIndex}-q-${questionIndex}`}
                                >
                                  Q {tempTotalQuestion} : {question.gainedMarks}
                                </a>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                      <Divider type="horizontal" />
                    </div>
                  );
                else return null;
              })}
          </div>
        </Modal> */}
        {/* 
        <Draggable defaultPosition={position}>
          <div style={{ width: "100px", height: "20px" }}>
            <Button onClick={handleClickOpen}>button</Button>
          </div>
        </Draggable> */}

        <Drawer
          title="Overview"
          onClose={onCloseOverviewDrawerOri}
          open={isOpenOverviewDrawer}
          width={isMobileWidth ? "80vw" : "30vw"}
          placement="left"
        >
          <div>{handleRenderQuestions()}</div>
        </Drawer>
      </ManualScoringWrapper>
    </Loading>
  );
};
