import styled, { DefaultTheme } from "styled-components";

export const SignInButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props: DefaultTheme) => props.theme.colorPalette.blackColor};

  .next-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    background: #f1f5f9;
    border-radius: 32px;
    padding: 10px 15px;
    padding-bottom: 14px;
    text-align: center;
    .next-inner-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 8px;
      background: #f1f5f9;
      box-shadow: 8px 8px 16px #c9d9e8, -8px -8px 16px #ffffff;
      border-radius: 24px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 1;
      z-index: 1;

      position: relative;
      overflow: hidden;
      transition: all transform 0.3s, box-shadow 0.1s;
    }

    .next-inner-wrapper:active {
      transform: translate(2px);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }

    .next-inner-wrapper:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-100%);
      transition: transform 0.3s;
    }

    .next-inner-wrapper:active:before {
      transform: translateY(0);
    }
  }
`;
