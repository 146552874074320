import {
  AzureCommunicationTokenCredential,
  MicrosoftTeamsUserIdentifier,
} from "@azure/communication-common";
import {
  CallComposite,
  CallCompositeOptions,
  CompositeLocale,
  TeamsCallAdapter,
  useTeamsCallAdapter,
  Profile,
  useCall,
  useCallAgent,
} from "@azure/communication-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MeetingGUIContainer } from "./style";
import CompanyLogo from "../company-logo";
import { useApplicationContext } from "../../context/application-context";
import { isMobileWidth } from "../../app-routes";
import { Avatar, Button, List, Modal } from "antd";

export type ContainerProps = {
  userId: MicrosoftTeamsUserIdentifier;
  token: string;
  formFactor?: "desktop" | "mobile";
  callInvitationURL?: string;
  locale?: CompositeLocale;
  options?: CallCompositeOptions;
  meetingUrl?: string;
  locator?: string;
};

interface LobbyParticipantListInterface {
  displayName: string;
  microsoftTeamsUserId: string;
  participantId: string;
}

export const TeamsMeeting = (props: ContainerProps): JSX.Element => {
  const { tenantSettings } = useApplicationContext();

  const [isLobbyModalOpen, setIsLobbyModalOpen] = useState(false);
  const [isStopInterval, setIsStopInterval] = useState(false);
  const [isAdmitClicked, setIsAdmitClicked] = useState(false);

  const [lobbyParticipantsList, setLobbyParticipantsList] =
    useState<LobbyParticipantListInterface[]>();

  // const adapterRef = useRef<TeamsCallAdapter | null>(null);
  const adapterRef = useRef<any | null>(null);

  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token);
    } catch (error) {
      console.error("Failed to construct token credential", error);
      return undefined;
    }
  }, [props.token]);

  const onFetchProfile = useCallback(
    async (userId: string, defaultProfile?: Profile): Promise<Profile> => {
      if (defaultProfile?.displayName) {
        return defaultProfile;
      }
      return { displayName: "HM" };
    },
    []
  );

  const options = useMemo(
    () => ({
      onFetchProfile,
    }),
    [onFetchProfile]
  );

  const teamsCallAdapterArgs = useMemo(
    () => ({
      userId: props.userId,
      credential,
      locator: props.meetingUrl
        ? {
            meetingLink: props.meetingUrl,
          }
        : undefined,
      options,
    }),
    [props.userId, credential, props.meetingUrl, options]
  );

  const adapter: any = useTeamsCallAdapter(
    teamsCallAdapterArgs,
    undefined,
    leaveCall
  );

  useEffect(() => {
    if (adapter) {
      adapterRef.current = adapter;

      const handleCallStarted = () => {
        console.log("Meeting started at:", new Date());
      };

      const handleCallEnded = () => {
        console.log("Meeting ended at:", new Date());
        localStorage.setItem("end_time", new Date().toISOString());
        setTimeout(() => {
          localStorage.setItem(
            "setTimeoutCheck",
            `{date:${new Date().toISOString()} `
          );
        }, 3000);
      };

      adapter.on("callEnded", handleCallEnded);

      // gold
      // adapter.on("participantsJoined", admitAllEvent);

      return () => {
        adapter.off("callEnded", handleCallEnded);
      };
    }
  }, [adapter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // console.log("Interval running");

      if (adapterRef.current) {
        console.log({ isAdmitClicked });

        checkAdapterForLobbyParticipants();
      }

      if (isStopInterval) {
        clearInterval(intervalId);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [isStopInterval]);

  const checkAdapterForLobbyParticipants = () => {
    console.log({ adapter: adapterRef.current });

    if (adapterRef.current?._call?.lobby?._participants) {
      const participants = adapterRef.current?._call?.lobby?._participants;

      console.log({ participants });

      const partListArray: LobbyParticipantListInterface[] = [];

      participants?.forEach((item: any) => {
        console.log({ item });
        const tempItem: LobbyParticipantListInterface = {
          displayName: item?._displayName,
          microsoftTeamsUserId: item?._identifier?.microsoftTeamsUserId,
          participantId: item?._identifier?.rawId,
        };

        partListArray.push(tempItem);
      });

      console.log({ partListArray });
      setLobbyParticipantsList(partListArray);

      if (partListArray.length > 0) {
        // if (!isLobbyModalOpen) {
        setIsLobbyModalOpen(true);
        // }
      } else {
        // if (isLobbyModalOpen) {
        setIsLobbyModalOpen(false);
        // }
      }
    }
  };

  if (!props.meetingUrl) {
    return <>Teams meeting link is not provided.</>;
  }

  const admitAllEvent = async (e: any) => {
    try {
      await e.joined[0]._call.lobby.admitAll();
    } catch (err) {
      console.log("Error in event admit all", { err });
    }
  };

  const getParticipantsList = () => {
    try {
      console.log(
        "participants list...",
        adapterRef.current?._call?.lobby?._participants
      );
    } catch (err) {
      console.log("Error in part list...", err);
    }
  };

  const admitAll = () => {
    try {
      adapterRef.current?._call.lobby.admitAll();

      setIsAdmitClicked(true);

      setLobbyParticipantsList([]);

      setTimeout(() => {
        setIsAdmitClicked(false);
      }, 4000);

      setIsLobbyModalOpen(false);
    } catch (err) {
      console.log("error on admit all...", err);
    }
  };

  if (adapter) {
    return (
      <MeetingGUIContainer>
        {/* <Button onClick={getParticipantsList}>List</Button>
        <Button onClick={admitAll}>admitAll</Button> */}

        <CallComposite
          options={{
            branding: {
              logo: {
                url: tenantSettings?.tenantConfig.brandLogo.darkBgLogo,
                alt: "",
                shape: "unset",
              },
              backgroundImage: {
                url: tenantSettings?.tenantConfig.loginBgURL,
              },
            },
          }}
          adapter={adapter}
          formFactor={isMobileWidth ? "mobile" : "desktop"}
          callInvitationUrl={props?.callInvitationURL}
          locale={props?.locale}
        />

        <Modal
          title="Waiting in Lobby"
          open={isLobbyModalOpen}
          // open={false}
          // onOk={handleAdmitAllOk}
          // onCancel={handleCancelAdmitAll}
          footer={() => (
            <>
              <Button
                onClick={admitAll}
                style={{
                  background:
                    tenantSettings.tenantConfig.colorPalette.primaryColor,
                  color: "white",
                  padding: "0px 30px",
                }}
              >
                ADMIT {lobbyParticipantsList?.length! > 1 && "ALL"}
              </Button>
            </>
          )}
        >
          {/* {lobbyParticipantsList?.map((item, index) => { */}
          {/* return ( */}
          <List
            itemLayout="horizontal"
            dataSource={lobbyParticipantsList}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "light",
                  }}
                  avatar={
                    <Avatar
                      src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${
                        index + 1
                      }`}
                    />
                  }
                  title={
                    <div style={{ marginTop: "5px" }}>{item.displayName}</div>
                  }
                />
              </List.Item>
            )}
          />
        </Modal>
      </MeetingGUIContainer>
    );
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>;
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "60vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <style>
        {`
      @keyframes growShrink {
        0% { transform: scale(1); }
        50% { transform: scale(1.5); }
        100% { transform: scale(1); }
      }

      .animatedLogo {
        animation: growShrink 2s infinite;
      }
    `}
      </style>
      <div className="animatedLogo">
        <CompanyLogo
          brandLogo={tenantSettings.tenantConfig.brandLogo.whiteBgLogo}
        />
      </div>
    </div>
  );
};

const leaveCall = async (adapter: TeamsCallAdapter): Promise<void> => {
  await adapter.leaveCall().catch((e) => {
    console.error("Failed to leave call", e);
  });
};
