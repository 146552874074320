import React from "react";
import { Spin, SpinProps } from "antd";
import { LoadingContainer } from "./styles";

interface LoadingProps extends SpinProps {
  minParentHeight?: boolean;
}

export const Loading: React.FC<LoadingProps> = (_props) => {
  const spinProps = { tip: "Loading ...", ..._props };

  if (_props.minParentHeight && spinProps.spinning) {
    spinProps.wrapperClassName = "spin-parent-height";
  }
  if (_props.children) {
    return <Spin {...spinProps}></Spin>;
  }

  return (
    <LoadingContainer>
      <Spin {...spinProps} style={{ color: "green" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            minWidth: "200px",
            minHeight: "300px",
          }}
        ></div>
      </Spin>
    </LoadingContainer>
  );
};
