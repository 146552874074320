import React, { useState } from "react";
import { Modal, Button, Layout } from "antd";
import { RecoilRoot } from "recoil";
import { Header, Content } from "antd/es/layout/layout";
import { useApplicationContext } from "./context/application-context";
import CompanyLogo from "./components/company-logo";
import AppRoute, { isMobileWidth } from "./app-routes";

function App() {
  const { tenantSettings } = useApplicationContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    window.close();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <RecoilRoot>
      <Layout>
        <Header
          style={{
            display: "flex",
            width: "100vw",
            padding: isMobileWidth ? "10px" : "20px",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#e9e9e9",
            borderBottom: `2px solid ${
              tenantSettings
                ? tenantSettings.tenantConfig.colorPalette.primaryColor
                : "gray"
            } `,
          }}
        >
          {tenantSettings.tenantConfig.brandLogo && (
            <CompanyLogo
              brandLogo={tenantSettings.tenantConfig.brandLogo.whiteBgLogo}
            />
          )}
          <Button
            onClick={showModal}
            style={{
              marginBottom: "4px",
              background: "darkred",
              color: "white",
            }}
          >
            Close Window
          </Button>
          <Modal
            title="Confirm Close"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Close"
            cancelText="Cancel"
          >
            <p>Are you sure you want to close the window?</p>
          </Modal>
        </Header>
        <Content style={{ height: "calc(100vh - 60px)" }}>
          <AppRoute />
        </Content>
      </Layout>
    </RecoilRoot>
  );
}

export default App;
